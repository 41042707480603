@import "./variables";
@import "components/themes/index";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $background-color;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mt-20 {
    margin-top: 20px;
}

.mt-15 {
    margin-top: 15px;
}
.theme-am .stepWrapper button[class*="Mui-selected"],
.theme-kz .stepWrapper button[class*="Mui-selected"],
.theme-kg .stepWrapper button[class*="Mui-selected"] {
    color: #ffffff !important; /* Цвет текста */
   }

.wrapper {
    border: 1px solid $light-grey-color;
    box-shadow: $block-shadow;
    position: relative;
    background-color: #fff;
    &__title {
        padding: 30px;
        color: $text-color;
        font-size: $text-size;
    }
    &__main {
        padding: 30px;
    }
    &__footer {
        padding: 20px;
        display: flex;
        justify-content: center;
    }
    &__actions {
        position: absolute;
        top: -20px;
        padding: 0 30px;
        button {
            margin-right: 10px;
            min-width: 200px;
        }
        &_white button {
            background-color: #fff;
        }
    }
    span {
        //font-size: 13px;
    }
    p {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
    }
}

.listWrapper {
    border: 1px solid $light-grey-color;
    box-shadow: $block-shadow;
    position: relative;
    background-color: #fff;
    min-height: calc(100vh - 112px);
    &_transparent {
        border: none;
        box-shadow: none;
        min-height: 0;
        .listWrapper__title {
            padding: 0;
            margin-bottom: 10px;
            font-size: 16px;
        }
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__title {
        padding: 30px;
        color: $text-color;
        font-size: $text-size;
        button {
            margin-left: 15px;
        }
    }
    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
    }
    &__main {
        padding: 30px;
    }
    &__footer {
        padding: 20px;
        display: flex;
        justify-content: center;
    }
    p {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
    }
}

.header2 {
    font-size: 18px;
    color: $text-color;
}

.header1 {
    font-size: 22px;
    color: #000;
    margin: 0;
    margin-bottom: 10px;
}

.form {
    height: 100%;
    //max-height: calc(100% - 60px);
    // min-height: calc(100vh - 208px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    button {
        margin-left: 10px;
    }
}

.spaceBtw {
    display: flex;
    justify-content: space-between;
    button {
        margin-left: 10px;
    }
}

.uploadedFiles {
    display: flex;
    align-items: center;
    margin-top: 20px;
    a {
        margin-left: 10px;
    }
}

.expandedPanel {
    margin: 30px 0;
    .MuiButtonBase-root.MuiExpansionPanelSummary-root {
        background-color: #f9f9f9;
    }
    .MuiExpansionPanelDetails-root {
        padding-top: 20px;
    }
}

.customError {
    margin: 0;
    font-size: 13px;
    color: red;
    text-align: center;
}

.textOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.warningMessage {
    font-size: 13px;
    color: $text-color;
}

.menuLogo {
    height: 40px;
    img {
        height: 100%;
    }
}

.MuiMenu-list[role='listbox'] {
    max-height: 300px;
}

.testMessage {
    color: red;
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 50px;
}

td {
    word-wrap: break-word;
    a {
        word-break: break-word;
    }
}

.shortName {
    display: flex;
    a {
        margin-left: 5px;
    }
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 600px;
    }
}

.theme-am .Mui-checked svg {
    fill: #cd1e24;
}

.theme-kz .Mui-checked path:nth-child(2),
.theme-am .Mui-checked path:nth-child(2),
.theme-kg .Mui-checked path:nth-child(2) {
    stroke: white;
    //fill: transparent;
}

.dataTableWrap {
    thead {
        background: rgb(251, 251, 251);
        border-top: 1px solid #f2f2f2;
    }
    th {
        font-size: 10px;
        font-weight: 700;
        line-height: 1.6;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: black;
        & > div:nth-child(1) {
            flex-wrap: initial;
            align-items: center;
        }
        div:before {
            left: -12px;
        }
    }
    th, td {
        padding: 15px;
        box-shadow: inset 0px -1px 0px #f2f2f2, inset -1px 0px 0px #f2f2f2;
    }
    button svg {
        fill: #e4d8d8;
    }
    div {
        word-break: break-word;
    }
    div[role='cell'] {
        //overflow: hidden;
        min-height: 50px;
    }
}

.theme-kg, .theme-kz, .theme-am {
    .dataTableWrap {
        button svg {
            fill: #e4d8d8;
        }
        button {
            color: white
        }
    }
}

.smallTableWrap {
    div[role="rowgroup"] > div:nth-child(1) {
        height: 500px !important;
        img {
            width: 220px;
        }
    }
}

div[role=columnheader] div {
    flex-wrap: inherit;
}

.actionIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    &_disabled {
        cursor: initial;
        opacity: 0.3;
    }
}

.stepWrapper {
    button {
        max-width: initial;
        color: #888;
    }
}

.productBlock {
    padding-left: 30px !important;
    border-left: 1px solid #f2f2f2;
}

.tabsWrapper {
    button {
        color: #888888;
    }
    button[aria-selected=true]{
        color: #2d2d2d;
    }
}

.datePickerField {
    margin-top: 16px;
    label {
        transform: translate3d(20px, 7px, 0) scale(0.75) !important;
    }
}

.sumAdd {
    color: green;
    font-weight: 600;
}

.sumSubtract {
    color: red !important;
    font-weight: 600 !important;
}

.buttonStyles button{
    font-size: 12px;
    line-height: 1.3333333333333333;
    text-transform: uppercase;
}

.buttonStylesSM button {
    font-size: 10px !important;
    line-height: 2;
    padding-left: 30px !important;;
    padding-right: 30px !important;;
    text-transform: uppercase;
}

div[role=presentation] {
    .MuiPaper-root {
        min-width: 320px;
    }
}

.notificationWrap {
    margin: 15px;
    span {
        font-weight: 600;
    }
    p {
        font-size: 18px;
    }
}

.errorPassword {
    color: red;
    font-size: 14px;
}

.withLink {
    a {
        color: #057ec6 ;
    }
}

.withoutMargin > div {
    margin: 0;
}


.formTitle {
    margin-top: 10px;
    margin-bottom: 5px;
    color: rgb(99, 102, 106);
    font-size: 18px;
}

.versionList {
    padding: 0;
    li {
        display: flex;
        padding: 10px;
        list-style: none;
        justify-content: space-between;
        width: 250px;
    }
}

p.subTitle {
    font-size: 14px;
    font-weight: normal;
    margin-top: 5px;
}

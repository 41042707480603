// Colors

$light-grey-color: #f2f2f2;
$text-color: #1F1F1F;
$grey-color: #888888;
$blue-color: #4aa5e8;
$yellow-color: #f6f42e;
$background-color: #F5F5F5;

$main-color: #057EC6;
$main-color-text: #ffffff;

html[data-theme='dark'] {
}

// Font

$text-size: 14px;


// Effects

$block-shadow: 2px 0px 15px rgba(0, 0, 0, 0.05);


$themes: (
  default: (
      main-color: #f6f42e,
      main-color-text: #888888,
  ),
   kg: (
       main-color: #057EC6,
       main-color-text: #ffffff,
   ),
   kz: (
       main-color: #0287B0,
       main-color-text: #ffffff,
   ),
   am: (
       main-color: #707070,
       main-color-text: #ffffff,
   ),
);

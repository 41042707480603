@import "../../variables";

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {

    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }

  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

.theme-kg, .theme-kz {
  .MuiMenu-list button,
  button[name='contained'],
  button[type='submit'],
  span[name='contained'] {
    color: white;
    path {
      fill: white;
    }
    &:hover {
      box-shadow: 0 5px 30px rgba($main-color, 0.4);
    }
  }
}

.theme-am {
  .MuiMenu-list button,
  button[name='contained'],
  button[type='submit'],
  span[name='contained'] {
    color: white;
    path {
      fill: white;
    }
    &:hover {
      box-shadow: 0 5px 30px rgba(#707070, 0.4);
    }
  }
}


.header {
  background-color: white;
  p {
    color: $text-color;
  }
  svg {
    @include themify($themes) {
      fill: themed('main-color-text');
    }
  }
}
li[role='menuitem'],
li[class*='DropDown-menuItemDefault'],
.MuiMenuItem-root.Mui-selected {
  &:focus {
    @include themify($themes) {
      color: themed('main-color-text');
    }
    path {
      @include themify($themes) {
        fill: themed('main-color-text');
      }
    }
  }
}

.wrapper__footer button{
  @include themify($themes) {
    //color: themed('main-color-text');
    color:#d3d3d3;
  }
  path {
    fill: $grey-color;
  }
}

.MuiMenuItem-root.Mui-selected {
  @include themify($themes) {
    color: themed('main-color-text');
  }
}

.Mui-checked {
  path {
    &:nth-child(1) {
      @include themify($themes) {
        fill: themed('main-color');
      }
    }
    &:nth-child(2) {
      @include themify($themes) {
        fill: themed('main-color-text');
      }
    }
  }
}

.authPage .bg {
  @include themify($themes) {
    background-color: themed('main-color');
  }
}
